import React from "react"

import Layout from "../components/layout"
import BlogPostsListWithSeo from "../components/common/BlogPostsListWithSeo"

const CategoryPage = (props) => {
  const { pageContext, path } = props

  return (
    <Layout>
      <BlogPostsListWithSeo
        initialPosts={pageContext.posts}
        totalCount={pageContext.totals}
        path={path}
        metaKeywords={pageContext.metatags}
        metaTitle={`Posts in ${pageContext.name} category`}
        metaDescription={pageContext.description}
      />
    </Layout>
  )
}

export default CategoryPage
